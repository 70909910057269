<template>
  <div>
    <div v-if="avatar" class="animate-pulse">
      <div
        class="h-10 w-10 rounded-full bg-gray-200 shadow-custom dark:bg-dark-secondary"
      ></div>
    </div>

    <div v-if="rectangle" class="space-y-3">
      <div
        v-for="i in count"
        :key="i"
        class="h-[72px] animate-pulse rounded-lg bg-gray-200 shadow-custom dark:bg-dark-secondary"
      ></div>
    </div>

    <div
      v-if="text"
      class="h-8 max-w-md animate-pulse rounded-lg bg-gray-200 shadow-custom dark:bg-dark-secondary"
    ></div>

    <div v-if="userSearch" class="flex items-center">
      <div
        class="h-10 w-10 animate-pulse rounded-full bg-gray-200 shadow-custom dark:bg-gray-600"
      ></div>
      <div
        class="ml-3 h-5 w-3/5 animate-pulse rounded-lg bg-gray-200 shadow-custom dark:bg-gray-600"
      ></div>
    </div>

    <div
      v-if="card"
      class="grid grid-cols-1 gap-3 md:grid-cols-2 xl:grid-cols-3 xl:gap-10"
    >
      <div
        v-for="i in count"
        :key="i"
        class="h-60 w-full animate-pulse rounded-lg bg-gray-200 shadow-custom dark:bg-dark-secondary"
      ></div>
    </div>

    <div v-if="draw">
      <div class="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-4">
        <div
          v-for="i in count"
          :key="i"
          class="h-10 animate-pulse rounded-lg bg-gray-200 shadow-custom dark:bg-dark-secondary"
        ></div>
      </div>
      <div
        class="md:h-400px] mt-6 h-[200px] animate-pulse rounded-lg bg-gray-200 shadow-custom dark:bg-dark-secondary"
      ></div>
    </div>

    <div v-if="event" class="mx-auto max-w-screen-md animate-pulse">
      <div class="h-10 rounded bg-white dark:bg-dark-secondary"></div>
      <div class="mt-5 h-10 w-36 rounded bg-white dark:bg-dark-secondary"></div>
      <div class="mt-10 space-y-6">
        <div
          v-for="i in 4"
          :key="i"
          class="h-14 rounded bg-white dark:bg-dark-secondary"
        ></div>
      </div>
      <div class="mt-10 h-28 rounded bg-white dark:bg-dark-secondary"></div>
    </div>

    <div v-if="table" class="w-full overflow-x-auto rounded-t-lg animate-pulse">
      <table class="w-full border-collapse">
        <thead>
          <tr>
            <th
              v-for="i in 5"
              :key="i"
              class="px-6 py-5 text-xs tracking-wide text-left bg-white dark:bg-dark-primary border-b border-l-0 border-r-0 border-grey dark:border-grey/10 whitespace-nowrap uppercase dark:border-b-transparent"
            >
              <div
                class="h-1 w-full rounded bg-gray-200 p-2 dark:bg-dark-primary"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="i in isFullList ? 10 : 5"
            :key="i"
            class="bg-white border-b border-grey dark:border-grey/10 dark:bg-dark-primary hover:bg-grey/50 dark:hover:bg-grey/10"
          >
            <td class="px-6 py-5 text-sm align-middle whitespace-nowrap">
              <div
                class="h-1 w-full rounded bg-gray-200 p-2 dark:bg-dark-primary"
              />
            </td>
            <td class="px-6 py-5 text-sm align-middle whitespace-nowrap">
              <div
                class="h-1 w-full rounded bg-gray-200 p-2 dark:bg-dark-primary"
              />
            </td>
            <td class="px-6 py-5 text-sm align-middle whitespace-nowrap">
              <div
                class="h-1 w-full rounded bg-gray-200 p-2 dark:bg-dark-primary"
              />
            </td>
            <td class="px-6 py-5 text-sm align-middle whitespace-nowrap">
              <div
                class="h-1 w-full rounded bg-gray-200 p-2 dark:bg-dark-primary"
              />
            </td>
            <td class="px-6 py-5 text-sm align-middle whitespace-nowrap">
              <div
                class="h-1 w-full rounded bg-gray-200 p-2 dark:bg-dark-primary"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="filter" class="max-w-xl flex flex-wrap gap-3 animate-pulse">
      <div
        v-for="i in 4"
        :key="i"
        class="rounded-full h-10 w-20 py-2 px-5 shadow-custom bg-gray-200 dark:bg-dark-secondary border dark:border-none"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const {
  avatar = false,
  rectangle = false,
  text = false,
  card = false,
  table = false,
  count = 3,
  isFullList = false,
  userSearch = false,
  draw = false,
  event = false,
  filter = false,
} = defineProps<{
  avatar?: boolean
  rectangle?: boolean
  text?: boolean
  card?: boolean
  table?: boolean
  count?: number
  isFullList?: boolean
  userSearch?: boolean
  draw?: boolean
  event?: boolean
  filter?: boolean
}>()
</script>
